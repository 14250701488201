import { useReducer, useState } from 'react'
import './App.css';


const initState = {
	cname: 'John Doe',
	cjob: 'Executive Producer',
	cextmob: '+30',	
	cexttel: '+30',	
	cmob: '693&nbsp;123&nbsp;4567',
	ctel: '210&nbsp;123&nbsp;4567',
	cmobtel: '00306931234567',
	cteltel: '00302101234567',
	copyMessage: 'Copy Signature',
	flag: 'init',
}

const dataReducer = (state, action) => {

	const { type, payload } = action

	const clean = (phone) => {
		return (phone).replace(/\D/g, '')		
	}

	const insertZwnjAfterFirstCharacter = (str) => {
		const zwnj = '&zwnj;'
		const original = str.split('')
		original.splice(1, 0, zwnj)
		const new_string = original.join('')
		return new_string
	}

	const phoneFormat = (phone) => {
		// const cleaned = ('' + payload).replace(/\D/g, '')
		const cleaned = clean('' + payload)
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

		return (match)
			? insertZwnjAfterFirstCharacter(match[1]) + '&nbsp;' + insertZwnjAfterFirstCharacter(match[2]) + '&nbsp;' + insertZwnjAfterFirstCharacter(match[3])
			: '...'
	}

	const stringFormat = (string) => {
		return (string.trim() === '')
			? '...'
			: string.trim()
	}


	switch(type) {

		case 'updateName':
			return { ...state, cname: stringFormat(payload) }
		case 'updateJob':
			return { ...state, cjob: stringFormat(payload) }
			case 'updateMobileExtension':
				const newMobileExtension = insertZwnjAfterFirstCharacter(payload)
				return { ...state, cextmob: newMobileExtension }
			case 'updatePhoneExtension':
				const newPhoneExtension = insertZwnjAfterFirstCharacter(payload)
				return { ...state, cexttel: newPhoneExtension }
		case 'updateMobile':						
			return { 
				...state, 
				cmob: phoneFormat(payload),
				cmobtel: '00' + clean(state.cexttel) + clean(payload),
			}
		case 'updatePhone':
			return { 
				...state, 
				ctel: phoneFormat(payload),
				cteltel: '00' + clean(state.cextmob) + clean(payload),
			}
		case 'updateFlag':
			return { ...state, flag: payload }
		case 'updateCopyMessage':
			return { ...state, successResetMsg: payload }
		case 'resetState':			
			return { ...state, ...initState }
		default:
			throw new Error()

	}

}

function App() {

	const [ state, dispatch ] = useReducer(dataReducer, initState)

	// const [ emailClient, setEmailClient ] = useState('gmail')
	const [ emailClient ] = useState('outlook')
	const [ landlineActive, setLandlineActive ] = useState(true)
	
	const copySignature = () => {

		let text = document.getElementById('soab-signature-html');
		let selection = window.getSelection();
		let range = document.createRange();
		range.selectNodeContents(text);
		selection.removeAllRanges();
		selection.addRange(range);
		//add to clipboard.
		document.execCommand('copy');
		
	}

	const copyClickHandler = () => {

		if (state.flag === 'loading')
			return

		dispatch({
			type: 'updateFlag',
			payload: 'loading'
		})

		dispatch({
			type: 'updateCopyMessage',
			payload: 'Copying ... '
		})

		copySignature()

		setTimeout( () => {

			dispatch({
				type: 'updateCopyMessage',
				payload: 'Copied'
			})

			dispatch({
				type: 'updateFlag',
				payload: 'init'
			})
			
		}, 500)
					

	}


	const resetClickHandler = () => {

		if (state.flag === 'loading')
			return

		dispatch({
			type: 'resetState'
		})

		document.getElementById('theForm').reset()

	}


	return(
		<div className="App">
			<div id="wrap">

				<div id="form-wrap">
				
					<form id="theForm">
						<label>
							<input type="text" placeholder="Fullname" onChange={ (e) => { dispatch({ type: 'updateName', payload: e.target.value })}} />
							<span>FirstName LastName</span>
							<em>First and Last Name</em>
						</label>

						<label>
							<input type="text" placeholder="Job Position"  onChange={ (e) => { dispatch({ type: 'updateJob', payload: e.target.value })}} />
							<span>Job Position</span>
							<em>Your Job Position</em>
						</label>

						<label style={{ width: '50px'}}>
							<input style={{padding: '20px 2px', textAlign: 'center' }} type="text" placeholder="Code" onChange={ (e) => { dispatch({ type: 'updateMobileExtension', payload: e.target.value })}} />
							<span style={{left:'50%',transform:'translate(-50%,0)'}}>Code</span>
							<em>Code</em>
						</label>
						
						<label>
							<input type="text" placeholder="Mobile" onChange={ (e) => { dispatch({ type: 'updateMobile', payload: e.target.value })}} />
							<span>Mobile</span>
							<em>Your mobile</em>
						</label>

						<label style={{ width: '50px'}}>
							<input style={{padding: '20px 2px', textAlign: 'center' }} type="text" placeholder="Code" onChange={ (e) => { dispatch({ type: 'updatePhoneExtension', payload: e.target.value })}} />
							<span style={{left:'50%',transform:'translate(-50%,0)'}}>Code</span>
							<em>Code</em>
						</label>

						<label>
							<input type="text" placeholder="Phone" onChange={ (e) => { dispatch({ type: 'updatePhone', payload: e.target.value })}} />
							<span>Phone</span>
							<em>Your phone number</em>
						</label>

						<div className="clearfix"></div>
						
					</form>

					<div className="subform">
						
						{/* <section>
							<div className="client-select">
								<h3>Choose Email Client</h3>
								<div className="client-select-options">
									<label><input type="checkbox" onChange={ (e) => { setEmailClient('gmail') }} checked={ emailClient === 'gmail'} /> <span>Gmail</span></label>
									<label><input type="checkbox" onChange={ (e) => { setEmailClient('outlook') }} checked={ emailClient === 'outlook'} /> <span>Outlook</span></label>								
								</div>								
							</div>							
						</section> */}

						<section>
							<div className="client-select">
								<h3>Landline</h3>
								<div className="client-select-options">
									<label><input type="checkbox" onChange={ (e) => { setLandlineActive(true) }} checked={ landlineActive === true} /> <span>Visible</span></label>
									<label><input type="checkbox" onChange={ (e) => { setLandlineActive(false) }} checked={ landlineActive === false} /> <span>Hidden</span></label>								
								</div>								
							</div>							
						</section> 

						

						<section>
							<div style={{margin:'20px auto'}} className="buttons">
								<button
									className="selectable"
									onClick={ () => { copyClickHandler() }}									
									style={{ backgroundColor: 'rgb(128 189 1 / 1)'}}
								>					
									<div className="svg">
										<div className="svg-inner" style={{paddingBottom:'116.6666666667%'}}>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 420 490"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M35,0H280c19.33,0,35,19.33,35,35V350a35,35,0,0,1-35,35H35c-19.33,0-35-19.33-35-35V35A35,35,0,0,1,35,0Z"/><path d="M420,157.5V455a35.1,35.1,0,0,1-35,35H140a35.1,35.1,0,0,1-35-35V420H280a70,70,0,0,0,70-70V122.5h35a35.1,35.1,0,0,1,35,35Z"/></g></g></svg>
										</div>										
									</div>
									<div className="msg">{ state.copyMessage }</div>
								</button>

								<button
									className="selectable"
									onClick={ () => { resetClickHandler() }}									
								>
									<div className="svg">
										<div className="svg-inner" style={{paddingBottom:'124.9986607382%'}}>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373.34 466.67"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path fillRule="evenodd" d="M0,186.67V210H46.67V186.67c0-77.21,62.79-140,140-140s140,62.79,140,140-62.79,140-140,140H163.34V233.34L46.67,350,163.34,466.67V373.34h23.33c102.92,0,186.67-83.75,186.67-186.67S289.59,0,186.67,0,0,83.75,0,186.67Z"/></g></g></svg>
										</div>
									</div>
									<div className="msg">Reset Signature</div>
								</button>



								{/* { state.flag && <button id="selectable" onClick={ () => { copySignature() }}>Select Signature</button> }
								{ !state.flag && 
								<button 
									id="selectable" 
									onClick={ () => { 
										dispatch({ type: 'resetState' })
										formRef.current.reset()
									}} 
									style={{backgroundColor:'rgba(128,189,1,1)'}}
								>
									<div style={{float:'left',width:'28px',height:'28px',marginTop:'-5px',fill:'#FFF'}}>
										
									</div> 
								</button> } */}
							</div>
						</section>

					</div>						

				</div>

					<div id="soab-signature-html-wrap">

						<div className="soab-signature-wrap-bg">
							<img src="https://cambo-media.s3.eu-west-1.amazonaws.com/cambo/sgntr/email-tpl.png" alt="" />
						</div>

						<div id="soab-signature-html">

							{/*********************************************************************************************************************/}
							<table style={{ margin:0, padding:0, borderSpacing: 0, overflow: 'hidden', backgroundColor: 'transparent', borderCollapse: 'collapse'}} cellSpacing="0" cellPadding="0" border="0" width="100%" role="presentation">
								<tbody>
									<tr style={{ margin: 0, padding: 0 }}><td colSpan="3" style={{padding:0,margin:0,fontSize:0,height:0}} height="0">&zwnj;</td></tr>
									<tr style={{ margin: 0, padding: 0 }}>
										<td valign="top" align="left" style={{padding:0,margin:0}}>&zwnj;</td>
										<td valign="top" align="left" style={{padding:0,margin:0}}>
							
											<div style={{ width: '100%', margin: 0, padding: 0 }}>
												<table style={{ margin:0, padding:0, borderSpacing: 0, overflow: 'hidden', backgroundColor: 'transparent', textAlign: 'left', borderCollapse: 'collapse' }} cellSpacing="0" cellPadding="0" border="0" width="100%" role="presentation">
													<tbody>
														<tr style={{ margin: 0, padding: 0 }}>
															<td valign="top" align="left" style={{ padding:0, margin:0 }}>
																<table style={{ margin:0, padding:0, borderSpacing: 0, overflow: 'hidden', backgroundColor: 'transparent', borderCollapse: 'collapse'}} cellSpacing="0" cellPadding="0" border="0" width="344" role="presentation">
																	<tbody>
																		

																		<tr style={{ margin: 0, padding: 0 }}>
																			<td valign="top" colspan="2" align="left" width="165" style={{ padding: '0', textDecoration: 'none' }}>
																				<a href="https://sunofabeach.com" title="Sun of a Beach's Website">
																					<img width="165" src="https://assets.sunofabeach.com/email-signature/2024-Signature.png" alt="Sun of a Beach" />
																				</a>
																			</td>
																		</tr>
																		<tr style={{ margin: 0, padding: 0 }}>
																			<td valign="top" colspan="2" align="left" width="165" style={{ padding: '0', textDecoration: 'none' }}>
																				<a href="https://sunofabeach.com" title="Sun of a Beach's Website">
																					<img style={{display:'block'}} width="165" src="https://assets.sunofabeach.com/email-signature/wave.gif" alt="" />
																				</a>
																			</td>
																		</tr>
																		<tr style={{ margin: 0, padding: 0 }}>
																			<td valign="top" colspan="2" align="left" width="165" style={{ padding: '0', textDecoration: 'none' }}>
																				<a href="https://sunofabeach.com" title="Sun of a Beach's Website">
																					<img style={{display:'block'}} width="165" src="https://assets.sunofabeach.com/email-signature/your-summer-friend_black.png" alt="Your Summer Friend" />
																				</a>
																			</td>
																		</tr>
																		<tr style={{ margin: 0, padding: 0 }}>
																			<td valign="top" colspan="2" align="left" width="165" style={{ padding: '0', textDecoration: 'none' }}>
																				<a href="https://sunofabeach.com" title="Sun of a Beach's Website">
																					<img style={{display:'block'}} width="165" src="https://assets.sunofabeach.com/email-signature/wave.gif" alt="" />
																				</a>
																			</td>
																		</tr>
																		
																		<tr style={{ margin: 0, padding: 0 }}>
																			<td colspan="2" style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', fontWeight: '400', lineHeight: '14px', letterSpacing: '2px', padding: '8px 0 7px 0' }}>
																				<span>{ state.cname }</span>
																			</td>							
																		</tr>

																		<tr style={{ margin: 0, padding: 0 }}>
																			<td colspan="2" style={{ fontFamily: 'Arial, sans-serif', fontSize: '10px', fontWeight: '400', lineHeight: '10px', letterSpacing: '2px', padding: '0 0 0 0' }}>
																				<span>{ state.cjob }</span>
																			</td>							
																		</tr>

																		<tr style={{ margin: 0, padding: 0 }}>
																			<td width="10" style={{ fontFamily: 'Arial, sans-serif', padding: '10px 0 0 0' }}>													
																				<a href={`tel:${state.cmobtel}`} style={{ color: 'inherit', textDecoration: 'none' }}>
																					<img style={{ display: 'block' }} src="https://assets.sunofabeach.com/email-signature/mobile.png" width="9" height="17" alt="Mobile:" />
																				</a>
																			</td>
																			<td style={{ fontFamily: 'Arial, sans-serif', fontSize: '11px', fontWeight: '400', lineHeight: '11px', letterSpacing: '2.5px', padding: '10px 0 0 0', textDecoration: 'none' }}>
																				<div style={{ textDecoration: 'none', color: 'black' }}>
																					{ emailClient === 'gmail' &&
																						<a href={`tel:${state.cmobtel}`} style={{ color: 'inherit', textDecoration: 'inherit' }}>
																							<span className="link-tag-span" style={{ textDecoration: 'none', color: 'black' }} dangerouslySetInnerHTML={{ __html: `&nbsp;&nbsp;${state.cextmob}&nbsp;${state.cmob}`}}/>																
																						</a>
																					}
																					{ emailClient === 'outlook' &&
																						<span style={{ textDecoration: 'none', color: 'black' }} dangerouslySetInnerHTML={{ __html: `&nbsp;&nbsp;${state.cextmob}&nbsp;${state.cmob}`}}/>
																					}
																				</div>																
																			</td>
																		</tr>						

																		{ landlineActive &&
																		<tr style={{ margin: 0, padding: 0 }}>
																			<td width="10" style={{ fontFamily: 'Arial, sans-serif', padding: '8px 0 0 0' }}>													
																				<a href={`tel:${state.cteltel}`} style={{ color: 'inherit', textDecoration: 'none' }}>
																					<img style={{ display: 'block' }} src="https://assets.sunofabeach.com/email-signature/telephone.png" width="11" height="11" alt="Telephone:" />
																				</a>
																			</td>								
																			<td style={{ fontFamily: 'Arial, sans-serif', fontSize: '11px', fontWeight: '400', lineHeight: '11px', letterSpacing: '2.5px', padding: '8px 0 0 0', textDecoration: 'none' }}>
																				<div style={{ textDecoration: 'none', color: 'black' }}>
																					{ emailClient === 'gmail' &&
																						<a href={`tel:${state.cteltel}`} style={{ color: 'inherit', textDecoration: 'inherit' }}>
																							<span className="link-tag-span" style={{ textDecoration: 'none', color: 'black' }} dangerouslySetInnerHTML={{ __html: `&nbsp;&nbsp;${state.cexttel}&nbsp;${state.ctel}`}}/>
																						</a>
																					}
																					{ emailClient === 'outlook' &&
																						<span className="link-tag-span" style={{ textDecoration: 'none', color: 'black' }} dangerouslySetInnerHTML={{ __html: `&nbsp;&nbsp;${state.cexttel}&nbsp;${state.ctel}`}}/>
																					}														
																				</div>
																			</td>
																		</tr>
																		}

																		<tr style={{ margin: 0, padding: 0 }}>
																			<td width="10" style={{ fontFamily: 'Arial, sans-serif', padding: '10px 0 0 0' }}>
																				<a href="https://www.instagram.com/sunofabeachtowels/" style={{ color: 'inherit', textDecoration: 'none' }}>
																					<img style={{ display: 'block' }} src="https://assets.sunofabeach.com/email-signature/instagram.png" width="11" height="11" alt="Instagram:" />
																				</a>
																			</td>
																			<td style={{ fontFamily: 'Arial, sans-serif', fontSize: '9px', fontWeight: '400', lineHeight: '9px', letterSpacing: '1px', padding: '10px 0 0 0', textDecoration: 'none' }}>
																				<div style={{ textDecoration: 'none', color: 'black' }}>
																					<a href="https://www.instagram.com/sunofabeachtowels/" style={{ color: 'inherit', textDecoration: 'inherit' }}>
																						<span className="link-tag-span" style={{ textDecoration: 'none', color: 'black' }}>&nbsp;&nbsp;@SUNOFABEACHTOWELS</span>
																					</a>
																				</div>
																			</td>				
																		</tr>

																		<tr style={{ margin: 0, padding: 0 }}>
																			<td width="10" style={{ fontFamily: 'Arial, sans-serif', padding: '10px 0 0 0' }}>
																				<a href="https://sunofabeach.com" style={{ color: 'inherit', textDecoration: 'none' }}>
																					<img style={{ display: 'block' }} src="https://assets.sunofabeach.com/email-signature/site.png" width="11" height="10" alt="Website:" />
																				</a>
																			</td>
																			<td style={{ fontFamily: 'Arial, sans-serif', fontSize: '10px', fontWeight: '400', lineHeight: '10px', letterSpacing: '2px', padding: '10px 0 0 0', textDecoration: 'none' }}>
																				<div style={{ textDecoration: 'none', color: 'black' }}>
																					<a href="https://sunofabeach.com" style={{ color: 'inherit', textDecoration: 'inherit' }}>
																						<span className="link-tag-span" style={{ textDecoration: 'none', color: 'black' }}>&nbsp;&nbsp;SUNOFABEACH.COM</span>
																					</a>
																				</div>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							{/*********************************************************************************************************************/}			
					
						</div>
					</div>					
				
			</div>
		</div>
	)

}

export default App